const messages = {
	pt: {
		translations: {
			dashboard: {
				title: {
					inservice: "EM ATENDIMENTO",
					waiting: "AGUARDANDO ATENDIMENTO",
					onlineAgents: "ATENDENTES ONLINE",
					completedTickets: "ATENDIMENTOS FINALIZADOS",
					totalSentMessages: "TOTAL DE MSG ENVIADAS",
					totalReceivedMessages: "TOTAL DE MSG RECEBIDAS",
					newLeads: "NOVOS LEADS",
					averageHandlingTime: "TEMPO MÉDIO DE ATENDIMENTO",
					averageWaitTime: "TEMPO MÉDIO DE ESPERA",
					totalUserInteractions: "Total de Atendimento por Usuário",
					totalInteractions: "Atendimento Total",
					startDate: "Data Inicial",
					endDate: "Data Final",
					filter: "FILTRAR",
					showFilterDate: "VER ATENDIMENTOS POR PERIODO",
					showFilterUser: "VER ATENDIMENTOS POR USUÁRIO",
					hideFilter: "OCULTAR FILTRO",
				},
			},

			messageVariablesPicker: {
				label: "Variavéis disponíveis",
				vars: {
					contactFirstName: "Primeiro Nome",
					contactName: "Nome",
					user: "Atendente",
					greeting: "Saudação",
					protocolNumber: "Protocolo",
					date: "Data",
					hour: "Hora",
					ticket_id: "Nº do Chamado",
					queue: "Setor",
					connection: "Conexão",
				},
			},
			quickemessage: {
				toasts: {
					success: "Atalho adicionado com sucesso!",
					deleted: "Atalho removido com sucesso!",
				},
				title: "Respostas Rápidas",
				buttons: {
					add: "Nova Resposta",
				},
				dialog: {
					add: "Adicionar",
					edit: "Editar",
					form: {
						shortcode: "Atalho",
						message: "Mensagem",
						geral: "Global",
					},
					buttons: {
						cancel: "Cancelar",
						edit: "Salvar",
						attach: "Anexar",
						add: "Salvar",
					},
				},
				confirmationModal: {
					deleteTitle: "Exclusão",
					deleteMessage: "Esta ação é irreversível! Deseja prosseguir?",
				},
			},
			quickemessages: {
				toasts: {
					success: "Atalho adicionado com sucesso!",
					deleted: "Atalho removido com sucesso!",
				},
				title: "Respostas Rápidas",
				table: {
					shortcode: "Atalho",
					mediaName: "Arquivo",
					status: "Global",
					actions: "Ação",
				},
				searchPlaceholder: "Procurar",
				buttons: {
					add: "Adicionar",
					attach: "Anexar Arquivo",
					cancel: "Cancelar",
					edit: "Salvar",
				},
				confirmationModal: {
					deleteTitle: "Exclusão",
					deleteMessage: "Esta ação é irreversível! Deseja prosseguir?",
				},
			},
			signup: {
				title: "CADASTRE-SE",
				toasts: {
					success: "Usuário criado com sucesso! Faça seu login!!!.",
					fail: "Erro ao criar usuário. Verifique os dados informados.",
				},
				form: {
					name: "Nome",
					email: "Email",
					password: "Senha",
					company: "Nome da Empresa",
					phone: "Whatsapp (DDD + NÚMERO)",
				},
				buttons: {
					submit: "Cadastrar",
					login: "Já tem uma conta? Entre!",
				},
			},
			login: {
				title: "Login",
				form: {
					email: "Email",
					password: "Senha",
				},
				buttons: {
					submit: "Entrar",
					register: "Não tem um conta? Cadastre-se!",
					forgotpassword: "Recuperar Senha",
				},
			},
			custom: {
				form: {
					mainColor: "Cor Principal",
					scrollbarColor: "Barra de Rolagem",
					toolbarBackground: "Barra Superior",
					BackgroundPages: "Background / Loading",
				},
			},
			companies: {
				title: "Cadastrar Empresa",
				form: {
					name: "Nome da Empresa",
					plan: "Plano",
					token: "Token",
					submit: "Cadastrar",
					success: "Empresa criada com sucesso!",
				},
			},
			auth: {
				toasts: {
					success: "Login efetuado com sucesso!",
				},
				dueDate: {
					expiration: "Sua assinatura expira em",
					days: "dias!",
					day: "dia!",
					expirationToday: "Sua assinatura expira hoje!",
				},
				token: "Token",
			},

			connections: {
				title: "Conexões",
				toasts: {
					deleted: "Conexão com o WhatsApp excluída com sucesso!",
				},
				confirmationModal: {
					deleteTitle: "Deletar",
					deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
					disconnectTitle: "Desconectar",
					disconnectMessage:
						"Tem certeza? Você precisará ler o QR Code novamente.",
				},
				buttons: {
					add: "Adicionar WhatsApp",
					disconnect: "desconectar",
					tryAgain: "Tentar novamente",
					qrcode: "QR CODE",
					newQr: "Novo QR CODE",
					connecting: "Conectando",
				},
				toolTips: {
					disconnected: {
						title: "Falha ao iniciar sessão do WhatsApp",
						content:
							"Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
					},
					qrcode: {
						title: "Esperando leitura do QR Code",
						content:
							"Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
					},
					connected: {
						title: "Conexão estabelecida!",
					},
					timeout: {
						title: "A conexão com o celular foi perdida",
						content:
							"Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
					},
				},
				table: {
					name: "Nome",
					status: "Status",
					lastUpdate: "Última atualização",
					default: "Padrão",
					actions: "Ações",
					session: "Sessão",
					number: "Número do Whatsapp",
				},
			},
			whatsappModal: {
				title: {
					add: "Adicionar WhatsApp",
					edit: "Editar WhatsApp",
				},
				form: {
					name: "Nome",
					default: "Padrão",
					maxUseBotQueues:
						"Quantidade máxima de vezes que o chatbot vai ser enviado",
					expiresTicket: "Encerrar ticket Por inatividade após x horas",
					messageExpiresTicket: "Mensagem informando inatividade",
					maxUseBotQueuesRedirect: "Enviar para a fila após quantidade máxima",
					outOfHoursMessage: "Mensagem de fora de expediente",
					greetingMessage: "Mensagem de saudação",
					complationMessage: "Mensagem de conclusão",
					token: "Token para integração externa",
				},
				buttons: {
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar",
				},
				success: "Conexão salva com sucesso!!.",
			},
			qrCode: {
				message: "Leia o QrCode para iniciar a sessão",
			},
			contacts: {
        title: "Contatos",
        toasts: {
            deleted: "Contato excluído com sucesso!",
            deletedAll: "Todos os contatos foram deletados com sucesso!", // Nova tradução
            errorDeletingAll: "Erro ao deletar todos os contatos." // Nova tradução
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
            deleteTitle: "Deletar ",
            importTitlte: "Importar contatos",
            deleteMessage:
                "Tem certeza que deseja deletar este contato? Todos os atendimentos relacionados serão perdidos.",
            importMessage: "Deseja importar todos os contatos do telefone?",
            deleteAllTitle: "Confirmar Deleção", // Nova tradução
            deleteAllMessage: "Tem certeza que deseja deletar todos os contatos? Esta ação é irreversível." // Nova tradução
        },
        buttons: {
            import: "Importar Contatos",
            add: "Adicionar Contato",
            export: "Exportar Contato",
            deleteAll: "Deletar Todos os Contatos" // Nova tradução
        },
        table: {
            name: "Nome",
            whatsapp: "WhatsApp",
            email: "Email",
            actions: "Ações",
            empresa: "Empresa",
        },
    },
    forwardMessage: {
        text: "Encaminhada",
    },
    forwardMessageModal: {
        title: "Encaminhar mensagem",
        buttons: {
            ok: "Encaminhar",
				},
			},
			contactModal: {
				title: {
					add: "Adicionar contato",
					edit: "Editar contato",
				},
				form: {
					mainInfo: "Dados do contato",
					extraInfo: "Informações adicionais",
					name: "Nome",
					number: "Número do Whatsapp",
					email: "Email",
					extraName: "Nome do campo",
					extraValue: "Valor",
					empresa: "Empresa",
				},
				buttons: {
					addExtraInfo: "Adicionar informação",
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar",
				},
				success: "Contato salvo com sucesso.",
			},
			queueModal: {
				title: {
					add: "Adicionar fila",
					edit: "Editar fila",
				},
				form: {
					name: "Nome Da Fila",
					color: "Cor",
					greetingMessage: "Mensagem de saudação",
					complationMessage: "Mensagem de conclusão",
					outOfHoursMessage: "Mensagem de fora de expediente",
					token: "Token",
				},
				buttons: {
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar",
				},
			},
			userModal: {
				title: {
					add: "Adicionar usuário",
					edit: "Editar usuário",
				},
				form: {
					name: "Nome",
					email: "Email",
					password: "Senha",
					farewellMessage: "Mensagem de despedida",
					profile: "Perfil",
					whatsapp: "Conexão Padrão",
					startWork: "Inicio de trabalho",
					endWork: "Fim de trabalho",
				},
				buttons: {
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar",
				},
				success: "Usuário salvo com sucesso.",
			},
			companyModal: {
				title: {
					add: "Adicionar empresa",
					edit: "Editar empresa",
				},
				form: {
					name: "Nome",
					email: "Email",
					passwordDefault: "Senha",
					numberAttendants: "Usuários",
					numberConections: "Conexões",
				},
				buttons: {
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar",
				},
				success: "Empresa salva com sucesso.",
			},
			scheduleModal: {
				title: {
					add: "Novo Agendamento",
					edit: "Editar Agendamento",
				},
				form: {
					body: "Mensagem",
					contact: "Contato",
					sendAt: "Data de Agendamento",
					sentAt: "Data de Envio",
				},
				buttons: {
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar",
				},
				success: "Agendamento salvo com sucesso.",
			},
			tagModal: {
				title: {
					add: "Nova Tag",
					edit: "Editar Tag",
				},
				form: {
					name: "Nome",
					color: "Cor",
				},
				buttons: {
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar",
				},
				success: "Tag salvo com sucesso.",
			},
			ratingModal: {
				title: {
					add: "Adicionar menu de avaliação",
					edit: "Editar menu de avaliação",
				},
				buttons: {
					okAdd: "Salvar",
					okEdit: "Editar",
					cancel: "Cancelar",
					options: "Adicionar opção",
				},
				form: {
					name: "Nome",
					message: "Mensagem da Avaliação",
					options: "Opções de Avaliação",
					extraName: "Nome da opção",
					extraValue: "Valor da opção",
				},
				success: "Avaliação salva com sucesso!",
			},
			chat: {
				noTicketMessage: "Selecione um ticket para começar a conversar.",
			},
			ticketsManager: {
				buttons: {
					newTicket: "Novo",
				},
			},
			ticketsQueueSelect: {
				placeholder: "Filas",
			},
			tickets: {
				toasts: {
					deleted: "O atendimento que você estava foi deletado.",
				},
				notification: {
					message: "Mensagem de",
				},
				tabs: {
					open: { title: "Abertas" },
					closed: { title: "Resolvidos" },
					search: { title: "Busca" },
				},
				search: {
					placeholder: "Buscar atendimento e mensagens",
				},
				buttons: {
					showAll: "Todos",
				},
			},
			transferTicketModal: {
				title: "Transferir Atendimento",
				fieldLabel: "Digite para buscar usuários",
				fieldQueueLabel: "Transferir para fila",
				fieldQueuePlaceholder: "Selecione uma fila",
				fieldConnectionLabel: "Transferir para a Conexão",
				fieldConnectionPlaceholder: "Selecione uma conexão",
				noOptions: "Nenhum usuário encontrado com esse nome",
				buttons: {
					ok: "Transferir",
					cancel: "Cancelar",
				},
			},
			ticketsList: {
				pendingHeader: "Aguardando",
				assignedHeader: "Atendendo",
				noTicketsTitle: "Nada aqui!",
				noqueue: "Sem Fila",
				Assigned: "Atribuído:",
				noTicketsMessage:
					"Nenhum atendimento encontrado com esse status ou termo pesquisado",
				buttons: {
					accept: "Aceitar",
					reopen: "Reabrir",
					closed: "Fechar",
				},
			},
			newTicketModal: {
				title: "Criar Atendimento",
				fieldLabel: "Digite para pesquisar o contato",
				add: "Adicionar",
				fieldQueuePlaceholder: "Selecione uma fila",
				buttons: {
					ok: "Salvar",
					cancel: "Cancelar",
				},
			},
			mainDrawer: {
				listItems: {
					dashboard: "Dashboard",
					connections: "Conexões",
					tickets: "Atendimentos",
					quickMessages: "Respostas Rápidas",
					contacts: "Contatos",
					queues: "Filas & Chatbot",
					tags: "Tags",
					administration: "Administração",
					companies: "Empresas",
					users: "Usuários",
					settings: "Configurações",
					ratings: "Avaliação",
					helps: "Ajuda",
					messagesAPI: "API",
					schedules: "Agendamentos",
					campaigns: "Campanhas",
					annoucements: "Informativos",
					chats: "Chat Interno",
					financeiro: "Financeiro",
				},
				appBar: {
					user: {
						profile: "Perfil",
						logout: "Sair",
					},
					refresh: "Atualizar",
				},
			},
			languages: {
				undefined: "Idioma",
				"pt-BR": "Português",
				es: "Español",
				en: "English",
				tr: "Türkçe",
			},
			messagesAPI: {
				title: "API",
				textMessage: {
					number: "Número",
					body: "Mensagem",
					token: "Token cadastrado",
				},
				mediaMessage: {
					number: "Número",
					body: "Nome do arquivo",
					media: "Arquivo",
					token: "Token cadastrado",
				},
			},
			notifications: {
				noTickets: "Nenhuma notificação.",
			},
			quickMessages: {
				title: "Respostas Rápidas",
				buttons: {
					add: "Nova Resposta",
				},
				dialog: {
					shortcode: "Atalho",
					message: "Resposta",
				},
			},
			contactLists: {
				title: "Listas de Contatos",
				table: {
					name: "Nome",
					contacts: "Contatos",
					actions: "Ações",
				},
				buttons: {
					add: "Nova Lista",
				},
				dialog: {
					name: "Nome",
					company: "Empresa",
					okEdit: "Editar",
					okAdd: "Adicionar",
					add: "Adicionar",
					edit: "Editar",
					cancel: "Cancelar",
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage: "Esta ação não pode ser revertida.",
				},
				toasts: {
					deleted: "Registro excluído",
				},
			},
			contactListItems: {
				title: "Contatos",
				searchPlaceholder: "Pesquisa",
				buttons: {
					add: "Novo",
					lists: "Listas",
					import: "Importar",
				},
				dialog: {
					name: "Nome",
					number: "Número",
					whatsapp: "Whatsapp",
					email: "E-mail",
					okEdit: "Editar",
					okAdd: "Adicionar",
					add: "Adicionar",
					edit: "Editar",
					cancel: "Cancelar",
				},
				table: {
					company: "Empresa",
					name: "Nome",
					number: "Whatsapp",
					whatsapp: "Whatsapp",
					email: "E-mail",
					actions: "Ações",
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage: "Esta ação não pode ser revertida.",
					importMessage: "Deseja importar os contatos desta planilha? ",
					importTitlte: "Importar",
				},
				toasts: {
					deleted: "Registro excluído",
				},
			},
			campaigns: {
				title: "Campanhas",
				searchPlaceholder: "Pesquisa",
				buttons: {
					add: "Nova Campanha",
					contactLists: "Listas de Contatos",
				},
				table: {
					name: "Nome",
					whatsapp: "Conexão",
					contactList: "Lista de Contatos",
					status: "Status",
					scheduledAt: "Agendamento",
					completedAt: "Concluída",
					confirmation: "Confirmação",
					actions: "Ações",
				},
				dialog: {
					new: "Nova Campanha",
					update: "Editar Campanha",
					readonly: "Apenas Visualização",
					form: {
						name: "Nome",
						message1: "Mensagem 1",
						message2: "Mensagem 2",
						message3: "Mensagem 3",
						message4: "Mensagem 4",
						message5: "Mensagem 5",
						confirmationMessage1: "Mensagem de Confirmação 1",
						confirmationMessage2: "Mensagem de Confirmação 2",
						confirmationMessage3: "Mensagem de Confirmação 3",
						confirmationMessage4: "Mensagem de Confirmação 4",
						confirmationMessage5: "Mensagem de Confirmação 5",
						messagePlaceholder: "Conteúdo da mensagem",
						whatsapp: "Conexão",
						status: "Status",
						scheduledAt: "Agendamento",
						confirmation: "Confirmação",
						contactList: "Lista de Contato",
					},
					buttons: {
						add: "Adicionar",
						edit: "Atualizar",
						okadd: "Ok",
						cancel: "Cancelar Disparos",
						restart: "Reiniciar Disparos",
						close: "Fechar",
						attach: "Anexar Arquivo",
					},
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage: "Esta ação não pode ser revertida.",
				},
				toasts: {
					success: "Operação realizada com sucesso",
					cancel: "Campanha cancelada",
					restart: "Campanha reiniciada",
					deleted: "Registro excluído",
				},
			},
			announcements: {
				title: "Informativos",
				searchPlaceholder: "Pesquisa",
				buttons: {
					add: "Novo Informativo",
					contactLists: "Listas de Informativos",
				},
				table: {
					priority: "Prioridade",
					title: "Title",
					text: "Texto",
					mediaName: "Arquivo",
					status: "Status",
					actions: "Ações",
				},
				dialog: {
					edit: "Edição de Informativo",
					add: "Novo Informativo",
					update: "Editar Informativo",
					readonly: "Apenas Visualização",
					form: {
						priority: "Prioridade",
						title: "Title",
						text: "Texto",
						mediaPath: "Arquivo",
						status: "Status",
					},
					buttons: {
						add: "Adicionar",
						edit: "Atualizar",
						okadd: "Ok",
						cancel: "Cancelar",
						close: "Fechar",
						attach: "Anexar Arquivo",
					},
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage: "Esta ação não pode ser revertida.",
				},
				toasts: {
					success: "Operação realizada com sucesso",
					deleted: "Registro excluído",
				},
			},
			campaignsConfig: {
				title: "Configurações de Campanhas",
			},
			queues: {
				title: "Filas & Chatbot",
				table: {
					name: "Nome",
					color: "Cor",
					greeting: "Mensagem de saudação",
					actions: "Ações",
				},
				buttons: {
					add: "Adicionar fila",
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage:
						"Você tem certeza? Essa ação não pode ser revertida! Os atendimentos dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
				},
			},
			queueSelect: {
				inputLabel: "Filas",
				unique: {
					inputLabel: "Fila",
				},
			},
			users: {
				title: "Usuários",
				table: {
					id: "ID",
					status: "Status",
					name: "Nome",
					email: "Email",
					profile: "Perfil",
					whatsapp: "Conexão Padrao",
					startWork: "Inicio de trabalho",
					endWork: "Fim de trabalho",
					actions: "Ações",
				},
				buttons: {
					add: "Adicionar usuário",
				},
				toasts: {
					deleted: "Usuário excluído com sucesso.",
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage:
						"Todos os dados do usuário serão perdidos. Os atendimento abertos deste usuário serão movidos para a fila.",
				},
			},
			compaies: {
				title: "Empresas",
				table: {
					status: "Ativo",
					name: "Nome",
					email: "Email",
					numberAttendants: "Atendentes",
					numberConections: "Conexões",
					value: "Valor",
					namePlan: "Nome Plano",
					numberQueues: "Filas",
					useCampaigns: "Campanhas",
					useExternalApi: "Rest API",
					useFacebook: "Facebook",
					useInstagram: "Instagram",
					useWhatsapp: "Whatsapp",
					useInternalChat: "Chat Interno",
					useSchedules: "Agendamento",
					createdAt: "Criada Em",
					dueDate: "Vencimento",
					lastLogin: "Ult. Login",
					actions: "Ações",
				},
				buttons: {
					add: "Adicionar empresa",
				},
				toasts: {
					deleted: "Empresa excluído com sucesso.",
				},
				confirmationModal: {
					deleteTitle: "Excluir",
					deleteMessage:
						"Todos os dados da empresa serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
				},
			},
			helps: {
				title: "Central de Ajuda",
			},
			schedules: {
				title: "Agendamentos",
				confirmationModal: {
					deleteTitle: "Você tem certeza que quer excluir este Agendamento?",
					deleteMessage: "Esta ação não pode ser revertida.",
				},
				table: {
					contact: "Contato",
					body: "Mensagem",
					sendAt: "Data de Agendamento",
					sentAt: "Data de Envio",
					status: "Status",
					actions: "Ações",
				},
				buttons: {
					add: "Novo Agendamento",
				},
				toasts: {
					deleted: "Agendamento excluído com sucesso.",
				},
			},
			tags: {
				title: "Tags",
				confirmationModal: {
					deleteTitle: "Você tem certeza que quer excluir esta Tag?",
					deleteMessage: "Esta ação não pode ser revertida.",
				},
				table: {
					name: "Nome",
					color: "Cor",
					tickets: "Registros Tagdos",
					actions: "Ações",
				},
				buttons: {
					add: "Nova Tag",
				},
				toasts: {
					deleted: "Tag excluído com sucesso.",
				},
			},
			ratings: {
				title: "Avaliações",
				table: {
					name: "Nome",
					contacts: "Contatos",
					actions: "Ação",
				},
				toasts: {
					deleted: "Avaliação excluída com sucesso!",
					deletedAll: "Todas as avaliações foram excluídas com sucesso!",
				},
				buttons: {
					add: "Adicionar",
					deleteAll: "Deletar Todos",
				},
				confirmationModal: {
					deleteTitle: "Deletar ",
					deleteAllTitle: "Deletar Todos",
					deleteMessage: "Tem certeza que deseja deletar esta avaliação?",
					deleteAllMessage:
						"Tem certeza que deseja deletar todas as avaliações?",
				},
			},
			settings: {
				success: "Configurações salvas com sucesso.",
				title: "Configurações",
				settings: {
					userCreation: {
						name: "Criação de usuário",
						options: {
							enabled: "Ativado",
							disabled: "Desativado",
						},
					},
				},
			},
			messagesList: {
				header: {
					assignedTo: "Atribuído à:",
					dialogRatingTitle:
						"Deseja deixar uma avaliação de atendimento para o cliente?",
					dialogClosingTitle: "Finalizando o atendimento com o cliente!",
					dialogRatingCancel: "Resolver COM Mensagem de Despedida",
					dialogRatingSuccess: "Resolver e Enviar Avaliação",
					dialogRatingWithoutFarewellMsg: "Resolver SEM Mensagem de Despedida",
					ratingTitle: "Escolha um menu de avaliação",
					buttons: {
						return: "Retornar",
						resolve: "Resolver",
						reopen: "Reabrir",
						accept: "Aceitar",
						rating: "Enviar Avaliação",
					},
				},
			},
			messagesInput: {
				placeholderOpen: "Digite uma mensagem",
				placeholderClosed:
					"Reabra ou aceite esse ticket para enviar uma mensagem.",
				signMessage: "Assinar",
			},
			contactDrawer: {
				header: "Dados do contato",
				buttons: {
					edit: "Editar contato",
				},
				extraInfo: "Outras informações",
			},
			ticketOptionsMenu: {
				schedule: "Agendamento",
				delete: "Deletar",
				transfer: "Transferir",
				registerAppointment: "Observações do Contato",
				resolveWithNoFarewell: "Finalizar sem despedida",
				acceptAudioMessage: "Aceitar áudios do contato?",
				appointmentsModal: {
					title: "Observações do Contato",
					textarea: "Observação",
					placeholder: "Insira aqui a informação que deseja registrar",
				},
				confirmationModal: {
					title: "Deletar o atendimento do contato",
					message:
						"Atenção! Todas as mensagens relacionadas ao atendimento serão perdidas.",
				},
				buttons: {
					delete: "Excluir",
					cancel: "Cancelar",
				},
			},
			confirmationModal: {
				buttons: {
					confirm: "Ok",
					cancel: "Cancelar",
				},
			},
			messageOptionsMenu: {
				delete: "Deletar",
				edit: "Editar Mensagem",
				reply: "Responder",
				forward: "Encaminhar",
				toForward: "Encaminhar",
				talkTo: "Conversar Com",
				confirmationModal: {
					title: "Apagar mensagem?",
					message: "Esta ação não pode ser revertida.",
				},
			},
			privacyModal: {
				title: "Editar Privacidade do Whatsapp",
				buttons: {
					cancel: "Cancelar",
					okEdit: "Salvar",
				},
				form: {
					menu: {
						all: "Todos",
						none: "Ninguém",
						contacts: "Meus contatos",
						contact_blacklist: "Contatos selecionados",
						match_last_seen: "Semelhante ao Visto por Último",
						known: "Conhecidos",
						disable: "Desativada",
						hrs24: "24 Horas",
						dias7: "7 Dias",
						dias90: "90 Dias",
					},
					readreceipts: "Para atualizar a privacidade dos recibos de leitura",
					profile: "Para atualizar a privacidade da foto do perfil",
					status: "Para atualizar a privacidade do recados",
					online: "Para atualizar a privacidade online",
					last: "Para atualizar a privacidade do Último Visto",
					groupadd: "Para atualizar a privacidade de Adicionar a grupos",
					calladd: "Para atualizar a privacidade de Adicionar a Ligações",
					disappearing: "Para atualizar o Modo de Desaparecimento Padrão",
				},
			},
			backendErrors: {
				ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
				ERR_NO_DEF_WAPP_FOUND:
					"Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
				ERR_WAPP_NOT_INITIALIZED:
					"Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
				ERR_WAPP_CHECK_CONTACT:
					"Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
				ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
				ERR_WAPP_DOWNLOAD_MEDIA:
					"Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
				ERR_INVALID_CREDENTIALS:
					"Erro de autenticação. Por favor, tente novamente.",
				ERR_SENDING_WAPP_MSG:
					"Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
				ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
				ERR_OTHER_OPEN_TICKET:
					"Já existe um atendimento aberto para este contato.",
				ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
				ERR_USER_CREATION_DISABLED:
					"A criação do usuário foi desabilitada pelo administrador.",
				ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
				ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
				ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
				ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
				ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
				ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
				ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
				ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
				ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
				ERR_FETCH_WAPP_MSG:
					"Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
				ERR_QUEUE_COLOR_ALREADY_EXISTS:
					"Esta cor já está em uso, escolha outra.",
				ERR_WAPP_GREETING_REQUIRED:
					"A mensagem de saudação é obrigatório quando há mais de uma fila.",
				ERR_OUT_OF_HOURS: "Fora do Horário de Expediente!",
			},
		},
	},
};

export { messages };
